<template>
  <div id="preim" class="align">
    <div class="sec_4 contentBlock">
      <div class="title">
        <h3>Наши преимущества</h3>
      </div>

      <div>
        <div class="point">
          <div>
            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.304 32.28l-.535-1.478a5.478 5.478 0 00-1.226-1.97v0a4.684 4.684 0 00-3.348-1.443h-7.391a4.687 4.687 0 00-3.348 1.443v0a5.478 5.478 0 00-1.226 1.97l-.535 1.478M25.818 14.293a5.38 5.38 0 11-7.61 7.609 5.38 5.38 0 017.61-7.61M14.671 5.873H9.706a3.837 3.837 0 00-3.838 3.838v4.966M30.324 39.133h4.967a3.837 3.837 0 003.838-3.838v-4.967M5.867 30.328v4.967a3.836 3.836 0 003.838 3.837h4.966M39.129 14.677V9.711a3.838 3.838 0 00-3.838-3.838h-4.967" stroke="#0D5740" stroke-width="2.935" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </div>
          <div>
            <h3>Только паспорт</h3>
            <p>Из документов необходим только паспорт РФ</p>
          </div>

        </div>
        <div class="point">
          <div>
            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M43.5 15.5h-42v-4.667l21-9.333 21 9.333V15.5zM5 30.663V21.33M40 21.33v9.333M16.668 21.33v9.333M28.336 21.33v9.333M1.5 36.502h42v7h-42v-7z" stroke="#0D5740" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </div>
          <div>
            <h3>Без кредитной истории</h3>
            <p>Мы не требуем кредитную историю</p>
          </div>

        </div>
        <div class="point">
          <div>
            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.987 40.112a17.12 17.12 0 01-17.12-17.12v0a17.12 17.12 0 0117.12-17.119v0a17.12 17.12 0 0117.12 17.12v0a17.12 17.12 0 01-17.12 17.12v0z" stroke="#0D5740" stroke-width="2.935" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M30.326 18.59l-9.17 9.782-5.504-5.87" stroke="#0D5740" stroke-width="2.935" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </div>
          <div>
            <h3>Нет штрафов</h3>
            <p>Полное отсутствие штрафов</p>
          </div>

        </div>
        <!--        <div class="point">-->
        <!--          <div>-->
        <!--            <img src="img/ico_6_blank.svg"/>-->
        <!--          </div>-->
        <!--          <div>-->
        <!--            <h3>Приедем сами</h3>-->
        <!--            <p>Нет необходимости выходить из дома, мы сами приедем к Вам</p>-->
        <!--          </div>-->

        <!--        </div>-->
        <div class="point">
          <div>
            <svg width="43" height="45" viewBox="0 0 43 45" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.742 17.014h39.512M36.864 6.037H6.132a4.39 4.39 0 00-4.39 4.39v28.537a4.39 4.39 0 004.39 4.39h30.732a4.39 4.39 0 004.39-4.39V10.427a4.39 4.39 0 00-4.39-4.39v0zM18.426 27.595l3.205-2.898M18.688 35.672h5.882M21.633 24.697v10.976M30.277 1.648v8.78M12.719 1.648v8.78" stroke="#0D5740" stroke-width="3.293" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </div>
          <div>
            <h3>Быстрый расчёт</h3>
            <p>Моментально перечисляем деньги</p>
          </div>

        </div>
        <div class="point">
          <div>
            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.29 24.734h15.457M8.402 31.459h8.715M17.118 42.66H6.09a4.446 4.446 0 01-4.413-4.481V6.815a4.447 4.447 0 014.413-4.481h22.538a4.447 4.447 0 014.413 4.48v13.442M38.184 24.078L24.086 38.176v4.48h4.48l14.099-14.098a2.24 2.24 0 000-3.167l-1.313-1.313a2.24 2.24 0 00-3.168 0v0z" stroke="#0D5740" stroke-width="3.36" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.763 15.78h-3.36V9.059h3.36a3.36 3.36 0 110 6.72z" stroke="#0D5740" stroke-width="3.36" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </div>
          <div>
            <h3>Работаем честно</h3>
            <p>Возвратная сумма прописана в договоре и не меняется</p>
          </div>

        </div>

        <div class="point pcOnly">
          <div>
            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M27.39 9.783h7.825a3.913 3.913 0 013.914 3.913v25.435a3.913 3.913 0 01-3.913 3.913H11.736a3.913 3.913 0 01-3.913-3.913V13.696a3.913 3.913 0 013.913-3.913h7.826" stroke="#0D5740" stroke-width="2.935" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M27.078 20.856a4.402 4.402 0 11-6.225 6.225 4.402 4.402 0 016.224-6.225M30.328 37.175a6.21 6.21 0 00-1.508-2.258v0a5.957 5.957 0 00-4.109-1.655H22.25a5.957 5.957 0 00-4.109 1.655v0a6.209 6.209 0 00-1.508 2.258M25.432 13.693H21.52a1.956 1.956 0 01-1.956-1.957v-5.87a1.956 1.956 0 011.956-1.956h3.913a1.956 1.956 0 011.957 1.957v5.87a1.956 1.956 0 01-1.957 1.956z" stroke="#0D5740" stroke-width="2.935" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </div>
          <div>
            <h3>С нами удобно.</h3>
            <p>Наша поддержка всегда на связи и ответит на все ваши вопросы</p>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "advantages"
}
</script>

<style scoped>
#preim {  }
.point { text-align: left; width: unset; padding-bottom: 6.93vw; display: flex; min-height: 9.07vw; align-items: center; }
.point > div > h3 { display: inline; padding-top: unset; padding-bottom: unset; margin-right: 1.33vw; color: #0D5740; }
.point > div > h3:after { content: "." }
.point > div > svg { height: 6.93vw; margin-right: 2.35vw; width: 6.93vw }
.point > div > div { width: 11.20vw; height: 13.87vw; text-align: center; }
.point > div > p { display: inline; line-height: 4.80vw; color: #5B5C5C; }
@media (min-width: 760px) {
  .sec_4 > div { display: flex; flex-wrap: wrap; justify-content: space-between}
  .point { width: 20.97vw; display: flex; flex-direction: column; align-items: center }
  .point > div > div { float: none; height: auto }
  .point > div > svg { height: 3.63vw; display: block; margin-bottom: 1.61vw }
  .point > div > h3 { display: block; margin-right: 0; margin-bottom: 0.65vw; text-align: center; font-size: 2.10vw }
  .point > div > h3:after { display: none }
  .point > div > p { display: block; line-height: 150%; text-align: center }
  #preim { }
}
@media (min-width: 1240px) {
  .sec_4 > div {  }
  .point { width: 260px; padding-bottom: 32px ;margin: 0}
  .point > div > div { }
  .point > div > svg { height: 45px; margin-right: 0; margin-bottom: 20px; width: 45px;  }
  .point > div > h3 { margin-right: 0; margin-bottom: 8px; font-size: 20px }
  .point > div > h3:after { display: none }
  .point > div > p { line-height: 150%}
  #preim {  }

  .point:nth-child(5), .point:nth-child(2){margin: 0 85px}
}
</style>